"use client";

import { loginUsuario } from '@redux/slices/auth/sesion';
import { useDispatch, useSelector } from 'react-redux'; // Asegúrate de usar 'react-redux'
import { useEffect } from 'react';
import Lazy from './Lazy';

const AuthProvider2 = ({ children }) => {
	const dispatch = useDispatch();
	const { token } = useSelector(state => state.sesion);

	useEffect(() => {
		const fetchData = async () => {
			try {
				await dispatch(loginUsuario()); // Asegúrate de que loginUsuario es una función asíncrona correcta
			} catch (error) {
				console.error('Error al iniciar sesión:', error);
			}
		};

		fetchData();
	}, [dispatch]);

	return (
		<div>
			{!token ? <Lazy /> : children}
		</div>
	);
};

export default AuthProvider2;
