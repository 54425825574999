import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import IconSwitcher from '@components/common/IconSwitcher';
import { THEME_COLORS } from '@theme/constants';
import { Button, Progress } from 'antd';
import React, { useState } from 'react';

const Lazy = () => {
  const [percent, setPercent] = useState(0);


  setTimeout(() => {
    if (percent === 100) {
      
    } else {
      setPercent(percent + 10);
    }
  }, 210);

  return (
    <div style={{
       height: '100vh', 
       width: '100vw', 
       background: THEME_COLORS.light.bg.alt , 
       display:'flex', 
       justifyContent:'center', alignItems:'center'}}>
      <IconSwitcher name="logo_square" size={4.5}/>
      
      <Progress percent={percent}
        strokeLinecap="square"
        showInfo={false}
        style={{ background: "#00000000", position: 'absolute', top: "-12px", zIndex: 100 }}
      />
    </div>
  );
};

export default Lazy;