import { createGlobalStyle } from "styled-components";
import { MEDIA_SCREENS, THEME_COLORS, CSS_HELPERS } from "./constants";
const mobile_font_titles = 0.9;
const mobile_font_p = 0.8;
const regular_font = 1;

const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;300;400;500;600;700;800;900&display=swap');
    
    body, p {
        font-family: 'Noto Sans', sans-serif !important;
    }

    .markdown-entradas{
        strong{
            color: ${THEME_COLORS.light.primary.alt};
        }
        a {
            &:hover{
                text-decoration: underline;
            }
        }

     line-height: 1.7;
    }


    // DROP DOWN MENU DON'T TOUCH THIS PLS
    .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title{
        padding: 0;
    }

    .ant-dropdown-menu-item:hover{
        background-color: #fff !important;
    }

    .retiros-alert .ant-modal-content{
        border-radius: 15px;
        font-size: 12px;
    }

    .retiros-alert .ant-modal-content p{
        font-size: 12px;
    }

    // .ant-carousel .slick-prev,
    // .ant-carousel .slick-prev:hover,
    // .ant-carousel .slick-prev:focus {
    //     font-size: 30px;
    //     left: 25px;
    //     z-index: 2;
    //     color: gray;
    // }

    // .ant-carousel .slick-next,
    // .ant-carousel .slick-next:hover,
    // .ant-carousel .slick-next:focus {
    //     font-size: 30px;
    //     right: 25px;
    //     z-index: 2;
    //     color: gray;
    // }

    
        user-select: none;
      
        /* @font-face {
            font-family: "NotoSans";
            font-weight:bold;
            src: url("/fonts/noto/NotoSans-Bold.ttf") format('woff');
        }
        @font-face {
            font-family: "NotoSans";
            src: url("/fonts/noto/NotoSans-BoldItalic.ttf") format('woff');
        }
        @font-face {
            font-family: "NotoSans";
            src: url("/fonts/noto/NotoSans-Italic.ttf") format('woff');
        }
        @font-face {
            font-family: "NotoSans";
            src: url("/fonts/noto/NotoSans-Regular.ttf") format('woff');
        }
         */

        .strong200{
            font-weight: 200;
        }
        .strong300{
            font-weight: 300;
        }
        .strong400{
            font-weight: 400;
        }
        .strong500{
            font-weight: 500;
        }
        .strong600{
            font-weight: 600;
        }
        .strong900{
            font-weight: 900;
        }
        .uppercase{
            text-transform:uppercase ;
        }
        .uppa{
            text-transform:uppercase ;
        }
        .cappi{
            text-transform:capitalize ;

        }
        .light_secondary_main{
            color: ${THEME_COLORS.light.secondary.main} 
        }

        .light_primary_contrastText{
            color:  ${THEME_COLORS.light.primary.contrastText} 
        }

        .light_primary_main{
            color: ${THEME_COLORS.light.primary.main} 
        }

        .light_secondary_contrastText{
            color:  ${THEME_COLORS.light.primary.contrastText} 
        }

        h1.ant-typography, 
        h2.ant-typography, 
        h3.ant-typography, 
        h4.ant-typography, 
        h5.ant-typography  {
            overflow-wrap: break-word;
            font-weight: 600;
            color: ${THEME_COLORS.light.text.titles};
        }

        p{
            margin:0 ;

        }
      
        @media screen and  (min-width: ${0}px) { 
            h1.ant-typography { font-size: ${1.8 * mobile_font_titles}rem }
            h2.ant-typography { font-size: ${1.6 * mobile_font_titles}rem }
            h3.ant-typography { font-size: ${1.5 * mobile_font_titles}rem }
            h4.ant-typography { font-size: ${1.2 * mobile_font_titles}rem }
            h5.ant-typography { font-size: ${.9 * mobile_font_titles}rem }
            p.xxs{font-size: ${.6 * mobile_font_p}rem }
            p.xs {font-size:  ${.8 * mobile_font_p}rem }
            p.sm{font-size:  ${.9 * mobile_font_p}rem }
            p {font-size: ${1.2 * mobile_font_p}rem }
            p.lg {font-size: 1.5rem }
            a.xxs{font-size: ${.6 * mobile_font_p}rem }
            a.xs {font-size:  ${.8 * mobile_font_p}rem }
            a.sm{font-size:  ${.9 * mobile_font_p}rem }
            a {font-size: ${1.2 * mobile_font_p}rem }
            a.lg {font-size: 1.5rem }
            span.xxs{font-size: ${.6 * mobile_font_p}rem }
            span.xs {font-size:  ${.8 * mobile_font_p}rem }
            span.sm{font-size:  ${.9 * mobile_font_p}rem }
            span {font-size: ${1.2 * mobile_font_p}rem }
            span.lg {font-size: 1.5rem }
        }
        @media screen and  (min-width: ${MEDIA_SCREENS.LG}px) { 
            h1.ant-typography { font-size: ${1.8 * regular_font}rem }
            h2.ant-typography { font-size: ${1.6 * regular_font}rem }
            h3.ant-typography { font-size: ${1.5 * regular_font}rem }
            h4.ant-typography { font-size: ${1.2 * regular_font}rem }
            h5.ant-typography { font-size: ${.9 * regular_font}rem }
            p.xxs{font-size: ${.6 * regular_font}rem }
            p.xs {font-size:  ${.8 * regular_font}rem }
            p.sm{font-size:  ${.9 * regular_font}rem }
            p {font-size: ${1.2 * regular_font}rem }
            p.lg {font-size: 1.5rem }
            a.xxs{font-size: ${.6 * regular_font}rem }
            a.xs {font-size:  ${.8 * regular_font}rem }
            a.sm{font-size:  ${.9 * regular_font}rem }
            a {font-size: ${1.2 * regular_font}rem }
            a.lg {font-size: 1.5rem }
            span.lg {font-size: 1.5rem }
            span.xxs{font-size: ${.6 * regular_font}rem }
            span.xs {font-size:  ${.8 * regular_font}rem }
            span.sm{font-size:  ${.9 * regular_font}rem }
            span {font-size: ${1.2 * regular_font}rem }
            span.lg {font-size: 1.5rem }
        }
        .strong{ 
            font-weight:600 
        };
        .subtitle { 
            color:${THEME_COLORS.light.primary.alt} 
        }
   

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-evenly {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.txt-center {
    text-align: center
}

.text-white {
    color: white;
}

.lineHeightStandarParragraph {
    line-height: 25px;
}

.txt-white {
    color: white;
}

.txt-right {
    text-align: right;
}

.m-auto {
    margin: auto;
}

.gap-10 {
    gap: 10px;
}

.gap-20 {
    gap: 20px;
}

.flex {
    display: flex;
}

.h-inherit {
    height: inherit;
}

.ant-drawer-body {
    /* padding: 0; */
}

.p-5 {
    padding: 5px;
}

.mt-30 {
    margin-top: 30px;
}

.w-100 {
    width: 100%;
}

.hidden {
    display: none !important;
}

.BaseDrawerMenu .ant-drawer-content-wrapper {
    height: 220px !important;
}


.verticalDrawerMenu .ant-drawer-content-wrapper {
    width: 250px;
}

.BaseDrawerMenu .ant-drawer-body {
    padding: 0px;
  }

.BaseDrawerMenu .ant-drawer-content {
    border-radius: 20px 20px 0 0;
}

.ant-drawer-title {
    /* font-size: 14px */
}


.drawerConfirmarRetiroEstrategico .ant-drawer-body,
.drawerRetiro1 .ant-drawer-body {
    padding: 0;
}

/* .drawerConfirmarRetiroEstrategico .ant-drawer-body, */
.drawerRetiro1 .ant-page-header {
    padding: 16px 24px;
}


.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${THEME_COLORS.light.primary.main}
}
.primary {
    background-color: #455a7e;
    border-color: #455a7e;
    color: white;
}
.mb-25 {
    margin-bottom: 25px;
}


.mt-10 {
    margin-top: 10px;
}

.mb3 {
    margin-bottom: 3% ;
}

.m-0 {
    margin: 0 !important;
}

.mt10 {
    margin-top: 10%;
}

.mt5 {
    margin-top: 5%;
}

.mt-6 {
    margin-top: 6px;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-4 {
    margin-top: 4px !important;
}

.pt5 {
    padding-top: 5%
}


.mb5 {
    margin-bottom: 5%;
}

.mb2 {
    margin-bottom: 2%;
}

.hoverable:hover {
    background: #fbfbfb;
}

.mb-10 {
 margin-bottom: 10px;
}


.mt-20 {
 margin-top: 20px;
}

.mb-20 {
 margin-bottom: 20px;
}

.flex-evenly {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.flex-column {
    flex-direction: column;
}

.text-center {
    text-align: center;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.p-r-10 {
  padding-right: 10px;
}

html, body {
  padding: 0;
  margin: 0;
  height: 100%;
  font-weight:400 ;
  /* color:${THEME_COLORS.light.text.paragraph}; */
}

html, body, .ant-typography {
    font-family: 'Noto Sans', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
    margin: 0;

}

* {
  box-sizing: border-box;
}

body div#__next {
  height: 100%;
  width: 100%;
}





.balance__tag {
    font-size: 13px;
    font-weight: bold;
}

.ant-table-cell {
    text-align: center;
}

.bold {
    font-weight: bold;
}


.info__person {
    font-weight: bold;
    margin-top: 15px;
    font-size: 15px;
    text-align: center;
    margin: 10px !important;
}

.width-100 {
    width: 100% !important;
}

.text-right {
    text-align: right;
}


.m-auto {
    margin: auto !important;
}



table thead tr th {
    text-align: center;
}

.flex {
    display: flex;
    align-items: center;
}


.ant-list-split .ant-list-item {
    border: none;
}


li.ant-list-item.casa:hover{
    background: #f9f9f9;
}



.ant-menu-overflow-item.ant-menu-item {
    display: flex;
    justify-content:center;
    align-items:center;
}


.border-rounded {
    border-radius: 0.6em
}

.site-page-header .ant-drawer-close {
    display: none;
}


/*******************/


.ant-picker-panels {
        width: 100%;
      
      @media (max-width: ${MEDIA_SCREENS.XS}px) { 
        display: flex;
        flex-direction: column;
      }
     
};



.ant-card{
    ${CSS_HELPERS.ROUNDED};
};
.ant-modal-content{
    ${CSS_HELPERS.ROUNDED};
};



.ant-btn {
    border-radius: .9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:.5em;
    span{
        font-size:1em;
        /* font-weight:600; */
    }
    &.ant-btn-circle {
        border-radius:50%;
    }
}

.btn_custom_secondary {
    border-color: ${THEME_COLORS.light.secondary.main} !important;
    background-color: ${THEME_COLORS.light.secondary.main} !important;
    color: ${THEME_COLORS.light.secondary.contrastText} !important;

    :hover, :focus {
        border-color: ${THEME_COLORS.light.secondary.main} !important;
        background-color: ${THEME_COLORS.light.secondary.contrastText} !important;
        color: ${THEME_COLORS.light.secondary.main} !important;
    }
    :active{
        border-color: ${THEME_COLORS.light.secondary.alt};
        background-color: ${THEME_COLORS.light.secondary.contrastText};
        color: ${THEME_COLORS.light.secondary.main};
    }
 
}

.btn_custom_secondary a {
    :hover {
        color: ${THEME_COLORS.light.secondary.main} !important;
    }
}


//:: GLOBAL UI
img {
    /* background-image: url('${process.env.IMAGE_LOADER}') */
}


//:: AND CUSTOMIZE


// MAIN ELEMENTS SHAPES UI


// INPUT NUMBER
.ant-input-number-input-wrap {
    height: 100%;
    .ant-input-number-input{
        height: 100%;
    }
}


//TEXT FIELD
.ant-custom-input {
    height: 2.5em !important;
    border-radius:${CSS_HELPERS.RADIUS} !important;
    background-color:${THEME_COLORS.light.bg.main} !important;
}
.ant-select-selector {
    border-radius:${CSS_HELPERS.RADIUS} !important;
    //background-color:${THEME_COLORS.light.bg.main} !important;
}
.ant-input-affix-wrapper{
    border-radius:${CSS_HELPERS.RADIUS};
    padding: 0 11px;
    background-color:${THEME_COLORS.light.bg.main} ;
    border: 1px solid ${THEME_COLORS.light.primary.alt};
    .ant-input{
        /* height:3.2em; */
    }
}


///TEXT FIELD NUMBER 
.ant-input-number{
    height: 3.2em;
    border-radius:${CSS_HELPERS.RADIUS};
    background-color:${THEME_COLORS.light.bg.main}; 
    width:100%;
}
// SELECT
.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border: none;
    background-color: transparent;
    border-radius:0;
    height: 100%;
    align-items: center;
}

.ant-select {
    height: 3.2em;
    border-radius:${CSS_HELPERS.RADIUS} ;
    background-color:${THEME_COLORS.light.bg.alt} ;
}


// DATE PICKER
.ant-picker{
    border-radius:${CSS_HELPERS.RADIUS} ;
    width: 100%;
    /* height: 3.2em; */
}


.ant-input-group{
    .ant-input{
        border-radius: 9pt 0 0 9pt;
        height: 3.2em;  
    }
    .ant-input-group-addon{
        height: 3.2em;
        width: 3.2em;
        border-radius: 0 9pt 9pt 0;
       overflow: hidden;
    }
    .ant-input-search-button {
       overflow: hidden;
       width: 100%;
       height: 100%;
       border-radius: 0 9pt 9pt 0 !important;
    }
}
.ant-image-mask{
    border-radius: ${CSS_HELPERS.RADIUS};
}


//DRAWER BOTTOM
.ant-drawer-bottom {
    @media screen and  (min-width: ${MEDIA_SCREENS.MD}px) { 
        display: flex;
        justify-content: center;
        width: 100%;
    }
}
.ant-drawer-bottom .ant-drawer-content-wrapper{

    @media screen and  (min-width: ${0}px) and (max-width: ${MEDIA_SCREENS.SM}px) { 
        width: 100vw;
    }
    @media screen and  (min-width: ${MEDIA_SCREENS.SM}px) and (max-width: ${MEDIA_SCREENS.MD}px) { 
        width: 100vw;
    }
    @media screen and  (min-width: ${MEDIA_SCREENS.MD}px) { 
        width: 90vw;
    }
}   
//END DRAWER BOTTOM
.custom_drawer{
    .ant-drawer-content{
        background:${THEME_COLORS.light.primary.alt + '00'};
    }
}

       
// END ANT DRAWER   


/* custom scrollbar */
::-webkit-scrollbar {
  width: 18px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.bx-siniestro-confirm::-webkit-scrollbar {
    width: 0px !important;
}

.hideXs{
    @media screen and  (min-width: ${0}px) and (max-width: ${MEDIA_SCREENS.SM}px) { 
            display:none;
    }
}

.noti{
    background-color:${THEME_COLORS.dark.bg.main};
   
    border-radius:${CSS_HELPERS.RADIUS};

    .ant-notification-notice-message, span{
        color:${THEME_COLORS.light.secondary.main};

    }
}


.ant-list-item-meta {
    align-items: center;
}



// MODAL DESIGN
.ant-modal-confirm-btns{
    display: flex;
    justify-content: flex-end;
    width:100%;
}
.drawer-siniestros .ant-drawer-body,
.drawer-siniestros-confirm .ant-drawer-body{
    padding: 0;
}
.drawer-siniestros .site-page-header,
.drawer-siniestros-confirm .site-page-header {
    background-color: #f0f2f5;
}

.drawer-siniestros-confirm .ant-drawer-body {
overflow: hidden;
}
.drawer-siniestros-confirm .bx-siniestro-confirm {
    overflow-y: auto;
    height: 100%;
    padding-bottom: 72px;
    background-color: #fbfbfb;
}
.drawer-siniestros-confirm .bx-siniestro-confirm .ant-card:nth-child(1) {
    width: 60%;
    margin: auto;
    margin-top: 10px;
    @media screen and (max-width: ${MEDIA_SCREENS.SM}px) {
     width: auto;
     margin:5px;
    }
.ant-modal-content {
    border-radius: ${CSS_HELPERS.RADIUS};
}

.ant-notification, .ant-notification-notice{
    border-radius:${CSS_HELPERS.RADIUS};
    overflow:hidden;
    padding:3em;
}
}`;





export default GlobalStyle;
