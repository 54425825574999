"use client";

import { AntdRegistry } from '@ant-design/nextjs-registry';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './GlobalStyle';

const AntProvider = ({ children }) => {
    return (
        <AntdRegistry>
            {/* <ThemeProvider theme={{ mode: 'light' }}>
                <GlobalStyle />
                {children}
            </ThemeProvider> */}
            <GlobalStyle />
            {children}
        </AntdRegistry>
    )
}

export default AntProvider;